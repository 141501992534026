export const ENGLISH = 'English';
export const ENGLISH_CODE = 'en';
export const US_LOCALE = 'en-US';
export const ENGLISH_FLAG = 'English flag';

export const CHINESE = '中文';
export const CHINESE_CODE = 'zh';
export const CHINESE_FLAG = 'Chinese flag';

export const MEXICAN = 'Español';
export const MEXICAN_CODE = 'mx';
export const MEXICAN_FLAG = 'Mexican flag';

export const MON = 'Mon';
export const TUE = 'Tue';
export const WED = 'Wed';
export const THU = 'Thu';
export const FRI = 'Fri';
export const SAT = 'Sat';
export const SUN = 'Sun';

export const STREET_ADDRESS1 = 'Address 1';
export const STREET_ADDRESS2 = 'Address 2';
export const CITY = 'City';
export const STATE = 'State';
export const ZIP_CODE = 'Zip Code';
export const COUNTRY = 'Country';

export const ACCOUNT = 'Account';
export const CREDIT_CARD = 'Credit card';
export const DEBIT_CARD = 'Debit card';
export const BANK_NAME = 'Bank Name';
export const ACCOUNT_NUMBER = 'Account Number';
export const ROUTING_NUMBER = 'Routing Number';
export const PAYMENT_TYPE = 'Payment type';
export const Pay = 'Pay';

export const OK = 'Ok';
export const YES = 'Yes';
export const NO = 'No';
export const CANCEL = 'Cancel';
export const CONFIRMATION = 'Confirmation';
export const ARE_YOU_SURE_YOU_WANT_TO_CANCEL = 'Are you sure you want to cancel?';
export const CONFIRM = 'Confirm';
export const LOADING = 'Loading';

export const STEP = 'Step';
export const OF = 'of';
export const PREVIOUS = 'Previous';
export const NEXT = 'Next';
export const SUBMIT = 'Submit';
export const CLOSE = 'Close';
export const TOGGLE_MENUS = 'Toggle menus';

export const ENLARGED_ATTACHMENT = 'Enlarged attachment';
export const NO_DATA = 'No Data';
export const MONTH_TO_MONTH = 'Month to month';
export const LEASED = 'Leased';
export const BEDROOMS = 'Bedrooms';
export const BATHROOMS = 'Bathrooms';
export const SIZE = 'Size';
export const LOGO = 'Logo';
export const POWERED_BY_MAGICDOOR = 'Powered by MagicDoor';

export const HOME = 'Home';
export const PAYMENTS = 'Payments';
export const MAINTENANCE = 'Maintenance';
export const COMMUNICATIONS = 'Communications';
export const SERVICES = 'Services';
export const SETTINGS = 'Settings';

export const WHEN_DUE = 'When due';
export const WEEKLY = 'Weekly';
export const MONTHLY = 'Monthly';
export const TWICE_A_MONTH = 'Twice a month';
export const FIXED_AMOUNT = 'Fixed amount';
export const AMOUNT = 'Amount';
export const MAXIMUM_AMOUNT = 'Maximum amount';
export const PAYMENT_FREQUENCY = 'Payment frequency';
export const POSTED_BALANCE = 'Posted balance';
export const CURRENT_BALANCE = 'Current balance';
export const CUSTOM_AMOUNT = 'Custom amount';
export const ACTIVE = 'Active';
export const PENDING = 'Pending';
export const WORK_ORDER = 'Work order';
export const CLOSED = 'Closed';
export const IN_PROGRESS = 'In progress';
export const CANCELLED = 'Cancelled';
export const ON_HOLD = 'On hold';
export const START_DATE = 'Start date';
export const END_DATE = 'End date';
export const DATE_AND_TIME = 'Date & Time';

export const SELECT_AN_ACCOUNT = 'Select an account';
export const SELECT_A_PAYMENT_TYPE = 'Select a payment type';
export const ENTER_A_VALID_AMOUNT = 'Enter a valid amount';
export const AMOUNT_MUST_HIGHER_THAT_MINIUM_PAYMENT_AMOUNT = 'Amount must be higher than the minimum payment amount({number})';
export const SELECT_A_PAYMENT_FREQUENCY = 'Select a payment frequency';
export const SELECT_A_FUTURE_PAYMENT_START_DATE = 'Select a future payment start date';
export const SELECT_AN_END_DATE_AFTER_START_DATE = 'End date must be after star date';
export const UNKNOWN = 'Unknown';
export const UNKNOWN_DATE = 'Unknown date';
export const YESTERDAY_AT_TIME = 'Yesterday at {time}';
export const DATE_AT_TIME = '{date} at {time}';
export const NO_MESSAGES = 'No messages';

export const FUTURE = 'Future';
export const DUE = 'Due';
export const OVERDUE = 'Overdue';
export const DUE_SOON = 'Due soon';
export const FAILED = 'Failed';
export const PAID = 'Paid';
export const PAYMENT = 'Payment';
export const RETURNED = 'Returned';
export const PROCESSING = 'Processing';
export const NONE = 'None';
export const REFUND = 'Refund';
export const CREDIT = 'Credit';
export const CHARGE = 'Charge';
export const DATE = 'Date';
export const STATUS = 'Status';
export const DESCRIPTION = 'Description';
export const TYPE = 'Type';
export const CURRENT_MAINTENANCE_REQUESTS = 'Current maintenance requests';
export const NO_MAINTENANCE_REQUESTS = 'No maintenance requests';
export const NOTIFICATIONS = 'Notifications';
export const PROCESSING_FEES = 'Processing fees';
export const TOTAL = 'Total';
export const CONFIRM_PAYMENT = 'Confirm Payment';
export const NEXT_PAYMENT = 'Next payment';
export const LOW = 'Low';
export const MEDIUM = 'High';
export const HIGH = 'Medium';
export const URGENT = 'Urgent';
export const ADD_ATTACHMENTS = 'Add attachments';
export const PERMISSION_TO_ENTER = 'Permission to enter';
export const URGENCY_TEXT = 'Urgency';
export const ACCOUNT_TYPE = 'Account type';
export const MY_CHATS = 'My chats';
export const SELECT_A_CHAT = 'Select a chat to begin';
export const SCROLL_TO_BOTTOM = 'Scroll to bottom';
export const SEND_A_MESSAGE = 'Send a message to begin';
export const TYPE_SOMETHING = 'Type something';
export const APP_IS_RECOMMENDED = 'Download the app for a better experience';
export const MESSAGE = 'Message';
export const OUTSANDING_BALANCE = 'Outstanding balance';
export const MAKE_PAYMENT = 'Make payment';
export const ADD_BANK_ACCOUNT = 'Add bank account';
export const NEW_MAINTENANCE_REQUEST = 'New maintenance request';
export const FIND_YOUR_PERFECT_PLACE = 'Find your perfect place';
export const TITLE = 'Title';
export const ASSIGNED_TO = 'Assigned to: ';
export const VIEW = 'View';
export const APPROVED = 'Approved';
export const VIEW_ALL = 'View all';
export const NO_NOTIFICATIONS = 'No notifications';
export const HI = 'Hi';
export const FEET = 'ft';
export const PROPERTIES_FOR_YOU = 'Properties for you';
export const PROPERTIES_FOR_YOU_BLURB = 'Properties for you blurb';
export const CREATE_REQUEST = 'Create request';
export const CREATED = 'Created';
export const LAST_UPDATED = 'Last updated';
export const MAINTENANCE_REQUESTS = 'Maintenance requests';
export const MAINTENANCE_REQUEST_DETAILS = 'Maintenance request details';
export const GENERAL = 'General';
export const REQUESTS_PER_PAGE = 'Requests per page';
export const PREV = 'Prev';
export const SUCCESS = 'Success!';
export const CONGRATULATIONS_PAYMENT = 'Congratulations! Your payment has been received';
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_AUTOPAYMENT = 'Are you sure you want to delete this auto payment?';
export const FINISH = 'Finish';
export const ADD_NEW = 'Add new';
export const PAYMENT_METHODS = 'Payment methods';
export const AUTO_PAYMENTS = 'Auto-payments';
export const NO_AUTO_PAYMENTS = 'You have no auto-payments set up for this lease.';
export const CONNECT_BANK_ACCOUNT = 'Connect bank account';
export const TRANSACTIONS = 'Transactions';
export const NO_TRANSACTIONS = 'You have no transactions yet for this lease';
export const AUTO_PAYMENT_CREATED = 'Auto-payment was successfully created!';
export const SET_UP_AUTO_PAYMENT = 'Set up an automatic payment';
export const NO_PAYMENTS_ALLOWED = 'No payments allowed at this time';
export const BALANCE_DETAIL = 'Balance detail';
export const LIST_OF_ACCOUNTS = 'Below is a list of your connected bank accounts with ACH';
export const SELECT_A_PAYMENT_METHOD = 'Select the payment method';
export const CHOOSE_ADD_UPDATE_PAYMENT_METHOD = 'Choose, add or update your payment method';
export const SAVED_PLAID_PAYMENT_METHODS = 'Saved Plaid payment methods';
export const SAVED_STRIPE_PAYMENT_METHODS = 'Saved Stripe payment methods';
export const MAGICDOOR_USES_PLAID = 'MagicDoor uses Plaid to connect your account';
export const MAGICDOOR_USE_STRIPE = 'MagicDoor uses Stripe to Secure your transaction';
export const PLAID_CONNECT_BLURB = 'Plaid lets you securely connect your financial accounts in seconds';
export const CONNECT_EFFORTLESSLY = 'Connect effortlessly';
export const ACCEPT_TERMS_OF_USE = 'Accept terms of use';
export const ACCEPT_TERMS_OF_USE_BLURB = 'By connecting your bank account you agree to our terms of use.';

export const INVALID_USER_NAME_OR_PASSWORD = 'Invalid user name or password';
export const SOMETHING_WENT_WRONG = 'Oops! Something went wrong!';
export const SIGN_IN = 'Sign in';
export const SIGN_OUT = 'Sign out';
export const EMAIL = 'E-mail';
export const PASSWORD = 'Password';
export const ALL_CHATS = 'All chats';
export const UPLOADED_FILES = 'Uploaded files';
export const UPDATE_REQUEST = 'Update request';
export const BACK = 'Back';
export const CLOSE_REQUEST = 'Close request';
export const GOR_TO_APP = 'Go to app';
export const TENANT_SIGN_IN = 'Tenant sign-in';
export const HELLO = 'Hello';
export const YOU_DO_NOT_HAVE_ANY_CHATS = 'You do not have any chats!';
export const YOU_NEED_TO_PROVIDE_A_CHAT_ID = 'You need to provide a chat ID!';
export const FAILED_TO_SUBSCRIBE_TO_CHAT_MESSAGES = 'Failed to subscribe to chat messages!';
export const FAILED_TO_UNSUBSCRIBE_FROM_CHAT_MESSAGES = 'Failed to unsubscribe from chat messages!';
export const YOU_NEED_TO_PROVIDE_A_MAINTENANCE_REQUEST_ID = 'You need to provide a maintenance ID!';
export const PLEASE_PROVIDE_A_DESCRIPTION_FOR_YOUR_REQUEST = 'Please provide a description for your request!';
export const YOU_NEED_TO_CREATE_OR_SELECT_AN_AUTOPAYMENT = 'You need to create or select an auto-payment!';
export const THERE_WERE_PROBLEMS_WITH_YOUR_REQUEST = 'There were problems with your request!';
export const YOU_NEED_TO_PROVIDE_A_PAYMENT_ID = 'You need to provide a payment ID!';
export const YOU_NEED_TO_SELECT_AN_ACCOUNT = 'You need to select an account!';
export const YOU_NEED_TO_SELECT_A_LEASE_TO_PAY = 'You need to select a lease to make a payment!';
export const YOU_NEED_TO_SELECT_A_PAYMENT_TYPE = 'You need to select a payment type!';
export const YOU_NEED_TO_SELECT_A_PAYMENT_AMOUNT = 'You need to select a payment amount!';
export const YOU_CANNOT_PAY_LESS_THAN_THE_MINIMUM = 'You cannot pay less than the minimum amount!';
export const YOU_NEED_TO_SELECT_A_LEASE = 'You need to select a lease';
export const INVALID_PAYMENT_AMOUNT = 'Invalid amount! You have to pay at least the minimum payment amount!';
export const COMPANY_NOT_ONBOARDED = 'It seems your company has not been onboarded yet. Please contact your property manager.';
export const YOU_DO_NOT_HAVE_ANY_LEASES = 'You do not have any leases!';
export const YOU_DO_NOT_HAVE_ANY_AVAILABLE_LEASE = 'You do not have any available leases!';
export const YOU_NO_NOT_SELECT_A_LEASE = 'You do not select a lease!';
export const NO_RENTAL_HISTORY_ERROR = 'You must provide at least one rental history!';
export const HTTP_STATUS_CODE = 'HTTP status code: ';
export const YOU_CANNOT_MAKE_A_PAYMENT_AT_THIS_TIME = 'You cannot make payments at this time. Contact your property manager!';
export const TERMS_OF_USE = 'Terms of use';
export const BY_AGREEING_TO_PAY_LINE1 = 'By clicking on confirm, you are agreeing to make this payment to';
export const BY_AGREEING_TO_PAY = 'By clicking on confirm, you are agreeing to make this payment';
export const BY_AGREEING_TO_PAY_ACH_BLURB = 'The payment will be made using ACH on date';
export const REVOKE_PAYMENT_AUTHORIZATION_BLURB = 'To revoke this authorization contact property manager';
export const YOUR_JOURNEY_BEGINS = 'Your journey begins here';
export const BY_AGREEING_TO_PAY_WITH_PLAID = 'By clicking on confirm, you are able to make this payment with Plaid account';

export const EDIT = 'Edit';
export const REMOVE = 'Remove';
export const APPLICANT_INFORMATION = 'Applicant information';
export const POTENTIAL_UNITS = 'Potential units';
export const RENTAL_HISTORY = 'Rental history';
export const WORK_HISTORY = 'Work history and income verification';
export const TERM_AND_CONDITIONS = 'Terms and conditions';
export const CONFIRM_YOUR_IDENTITY = 'Confirm your identity';
export const QUESTIONS = 'Questions';
export const RENTAL_APPLICATION = 'Rental application';
export const ARE_YOU_SURE_YOU_WANT_TO_DELETE_ACCOUNT = 'Are you sure you want to delete this account';
export const ARE_YOU_SURE_YOU_WANT_TO_UNLINK_ALL_PLAID = 'Are you sure you would like to unlink all your Plaid accounts';
export const SUBMIT_A_RENTAL_APPLICATION = 'Submit a rental application';
export const ADD_CREDIT_DEBIT_CARD = 'Add credit/debit card';
export const UNLINK = 'Unlink';
export const RESYNC = 'Resync';
export const ADD_CARD = 'Add card';
export const MAGICDOOR_TERMS = 'MagicDoor Terms and Conditions';
export const TRANS_UNION_TERMS = 'TransUnion Terms and Conditions';
export const LINK_BANK_ACCOUNTS = 'Link bank accounts';
export const BELOW_IS_A_LIST_OF_PLAID_ACCOUNTS = 'Below is a list of all your connected bank accounts';
export const CREDIT_DEBIT_CARDS = 'Credit/Debit cards';
export const BELOW_IS_A_LIST_OF_STRIPE_ACCOUNTS = 'Below is a list of all your connected  credit/debit cards';
export const NO_LINKED_ACCOUNTS = 'There are no linked accounts';
export const ADD_RENTAL_HISTORY = 'Add rental history';
export const REASON_FOR_LEAVING = 'Reason for leaving';
export const LANDLORD_NAME = 'Landlord name';
export const LANDLORD_PHONE = 'Landlord phone';
export const RENT = 'Rent';
export const MOVE_IN_DATE = 'Move-in date';
export const MOVE_OUT_DATE = 'Move-out date';
export const I_HAVE_READ_AND_AGREED_TO_THE_TERMS = 'I have read and agreed to the terms of service';
export const SELECTED_UNITS = 'Selected units';
export const ADD_EMPLOYMENT = 'Add employment';
export const EMPLOYMENT_HISTORY = 'Employment history';
export const ANNUAL_INCOME = 'Annual income';
export const NAME = 'Name';
export const PHONE = 'Phone';
export const POSITION = 'Position';
export const SALARY = 'Salary';
export const BANK_STATEMENT = 'Bank statement';
export const UPLOAD_GOVERNMENT_ID = 'Upload government ID';
export const UPLOAD_BANK_STATEMENT = 'Upload bank statement';
export const TRANS_UNION_CONNECTION = 'TransUnion connection';
export const START_SCREENING = 'Start screening';
export const PAYMENT_DETAILS = 'Payment details';
export const SUBMIT_ANSWERS = 'Submit answers';
export const PAYMENT_PROCESSING = 'Payment processing';
export const PAYMENT_PROCESSING_BLURB = 'Payment processing blurb';
export const DONE = 'Done';
export const RENTAL_APPLICATION_SUCCESSFULLY_SUBMITTED = 'Your rental application was successfully submitted';
export const RENTAL_APPLICATION_NOTIFICATION = 'The application status will be notified by email. Please check your email';
export const CONNECTING_TO_TRANS_UNION = 'Connecting to TransUnion to Prepare Questionnaire and Initiate ID Verification';
export const CONNECTING_TO_TRANS_UNION_BLURB = 'Connecting to TransUnion blurb';
export const CONTACT_CUSTOMER_SUPPORT = 'For completing your screening process, please contact our customer support at';
export const CONTACT_CUSTOMER_SUPPORT_BLURB = 'Customer support blurb';
export const UNABLE_TO_VERIFY_IDENTITY = 'We are unable to verify your identity online';
export const RENTER_SCREENING_ID = 'Renter screening ID: {renterID}';
export const PAGE_NOT_FOUND = 'Page Not Found';
export const PAGE_NOT_FOUND_BLURB_1 = 'Page not found blurb 1';
export const PAGE_NOT_FOUND_BLURB_2 = 'Page not found blurb 2';

export const SCAN_THE_QRCODE = 'Scan the code to open the migicdoor program.';
export const CURRENT_LEASE = 'Current lease';
export const NO_LEASE_YET = 'No lease yet';
export const BALANCES = 'Balances';
export const ONGOING_MAINTENANCE_REQUESTS = 'Ongoing maintenance requests';
export const NA = 'N/A';
export const GRANT_ENTRY = 'Grant Entry';
export const PREFERRED_MAINTENANCE_TIME = 'Preferred maintenance time';
export const ALL_MAINTEANCE_REQUESTS = 'All maintenance requests';

export const EVICTION = 'Eviction';
export const EXPRIED = 'Expired';
export const DRAFT = 'Draft';
export const ENDED_LEASE = 'Ended Lease';

export const LOW_AMOUNT = 'The payment amount is too low for processing. Please adjust the amount and try again.';
export const BANK_ACCOUNT_NOT_LINKED = 'Your bank account is not linked. Please link a bank account to proceed.';
export const PAYMENT_AMOUNT_TOO_LOW = 'The payment amount is below the minimum threshold. Please increase the amount and try again.';
export const INSUFFICIENT_FUNDS = 'There are insufficient funds in your account. Please add funds and try again.';
export const ACCOUNT_NOT_MAPPED = "There's an issue with your tenant account. Please contact support for assistance.";
export const TRANSFER_LIMIT_REACHED =
  'You have reached your transfer limit. Please try again later or contact support for limit adjustment.';
export const ACCOUNT_BLOCKED = 'Your account is currently blocked. Please contact support for further details.';
export const PAYMENT_EVICTION = 'Payments are restricted due to an ongoing eviction process. Please contact support for more information.';
export const PAYMENT_UNKNOWN_ERROR = 'An unknown error occurred. Please try again later. If the issue persists, contact support.';
export const FIRST_NAME = 'First name';
export const LAST_NAME = 'Last name';
export const SSN = 'SSN';
export const DATE_OF_BIRTH = 'Date of birth';
export const GOVERNMENT_ID = 'Government ID';
export const WELCOME_TO_MAGIC_DOOR =
  'Welcome to MagicDoor. By accessing our website, mobile application, or using our services, you agree to be bound by these Terms and Conditions.';
export const MAGIC_DOOR_PROVIDES_A_PLATFORM =
  'MagicDoor provides a digital platform for property management. Our services include, but are not limited to:';
export const RENT_COLLECTION = 'Rent collection';
export const MAINTENANCE_MANAGEMENT = 'Maintenance management';
export const TENANT_COMMUNICATION = 'Tenant communication';
export const FINANCIAL_REPORTING = 'Financial reporting';
export const RENTAL_APPLICATION_CHARGES_NON_REFUNDABLE = 'Please note that Rental Application charges are non-refundable.';
export const VIEW_IN_FULL_SCREEN = 'View in fullscreen';
export const SHOW = 'Show';
export const HIDE = 'Hide';
export const FIRST_NAME_IS_INVALID = 'First name is invalid';
export const LAST_NAME_IS_INVALID = 'Last name is invalid';
export const EMAIL_IS_INVALID = 'Email is invalid';
export const PHONE_NUMBER_IS_INVALID = 'Phone number is invalid';
export const DATE_OF_BIRTH_IS_INVALID = 'Date of birth is invalid';
export const ANNUAL_INCOME_IS_INVALID = 'Annual income is invalid';
export const SSN_IS_INVALID = 'SSN is invalid';
export const YOU_HAVE_TO_ACCEPT_THIS_LICENSE_AGREEMENT = 'You have to accept this license agreement';
export const EMPLOYER_NAME_IS_INVALID = 'Employer name is invalid';
export const POSITION_TITLE_IS_INVALID = 'Position/title is invalid';
export const START_DATE_IS_INVALID = 'Start date is invalid';
export const END_DATE_IS_INVALID = 'End date is invalid';
export const ADDRESS_IS_INVALID = 'Address is invalid';
export const STREET_ADDRESS_1_IS_INVALID = 'Street address 1 is invalid';
export const STREET_ADDRESS_2_IS_INVALID = 'Street address 2 is invalid';
export const REASON_FOR_LEAVING_IS_INVALID = 'Reason for leaving is invalid';
export const CITY_IS_INVALID = 'City is invalid';
export const STATE_IS_INVALID = 'State is invalid';
export const ZIP_CODE_IS_INVALID = 'Zip code is invalid';
export const COUNTRY_IS_INVALID = 'Country is invalid';
export const LANDLORD_NAME_IS_INVALID = 'Landlord name is invalid';
export const LANDLORD_PHONE_NUMBER_IS_INVALID = 'Landlord phone number is invalid';
export const RENT_IS_INVALID = 'Rent is invalid';
export const MOVE_IN_DATE_IS_INVALID = 'Move in date is invalid';

export const PAY = 'Pay';
export const RENTAL_APPLICATION_FEE_BLURB =
  'You are required to pay a non-refundable fee of {amount} to submit your application, that will go towards the screening costs.';

export const NO_COMMUNICATIONS = 'No communications';
export const RENTAL_APPLICATION_NOT_FOUND = 'Rental application not found';
export const QUESTIONAIRE = 'Questionaire';
export const APPLICATION_SUBMITTED = 'Application submitted';
export const MANUAL_VERIFICATION = 'Manual verification';
export const OOPS = 'Oops';
export const THIS_LEASE_HAS_BEEN_DEACTIVATED_TIPS =
  'This lease has been deactivated and you will not be able to perform any actions, please contact the administrator if you have any questions.';
export const CURRENT_PASSWORD = 'Current password';
export const NEW_PASSWORD = 'New password';
export const CONFIRM_NEW_PASSWORD = 'Confirm new password';
export const ENTER_PASSWORD = 'Enter password';
export const ENTER_NEW_PASSWORD = 'Enter new password';
export const PASSWORD_DO_NOT_MATCH = 'Passwords do not match';
export const UPDATE_PASSWORD = 'Update password';
export const ENTER_THE_CURRENT_PASSWORD_AND_NEW_PASSWORD = 'Enter the current password and new password';
export const LOGIN_PASSWORD = 'Login & Password';
export const SAVE = 'Save';
export const SETTINGS_UPDATED_MESSAGE = 'The settings has been updated, please save it in time';
export const NO_UPDATES_SAVED_MESSAGE = 'Note that there are no updates yet saved.';
export const BASIC_INFORMATION = 'Basic information';
export const UPDATE_TENANT_SUCCESS = 'Update tenant information successfully';
export const LANGUAGE = 'language';
export const PASSWORD_UPDATED_SUCCESSFULLY = 'Password updated successfully';
export const TENANT_PROFILE = 'Tenant profile';
export const UPDATE = 'Update';
export const DELETE_BANK_ACCOUNT = 'Delete Bank Account';
export const ARE_YOU_SURE_TO_DELETE_THIS_BANK_ACCOUNT = 'Are you sure to delete this bank account?';

export const PLAID_LINKED_BANKS = 'Plaid linked banks';
export const ALL_BANK_ACCOUNTS_LISTED = 'Below is a list of all your connected banks. Click on a bank to view its associated accounts.';
export const ADD_ANOTHER_ACCOUNT = 'Add Another Account';
export const DELETE_AUTO_PAYMENT = 'Delete Auto-Payment';
export const DELETE_AUTO_PAYMENT_CONFIRMATION = 'After this automatic payment is removed, subsequent bills need to be paid manually.';
export const YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_LINKED_TO_MAGICDOOR = 'Your account has been successfully linked to MagicDoor.';
export const PAYMENT_START_DATE = 'PAYMENT START DATE';
export const PAYMENT_START_DATE_PLACEHOLDER = 'Payment start date';
export const PAYMENT_END_DATE = 'PAYMENT END DATE';
export const PAYMENT_END_DATE_PLACEHOLDER = 'Payment end date';
export const CURRENT_BALANCE_DETAIL = 'CURRENT BALANCE DETAIL';
export const BALANCE_TYPE = 'Balance Type';
export const POSTED_BALANCE_TIPS =
  'The sum of charges that have been officially posted, including pending transactions and future charges.';
export const CURRENT_BALANCE_TIPS =
  'The real-time balance of your lease, taking into account all payments, credits, and charges that have occurred to date.';
export const AUTO_PAYMENT_DETAIL = 'Auto-Payment Detail';
export const SETTING_AUTOMATIC_PAYMENT_SUCCEEDED = 'Setting Automatic Payment Succeeded';
export const SECURELY_CONNECT_TO_YOUR_BANK_WITH_PLAID = 'Securely Connect to Your Bank with Plaid';
export const TO_FACILITATE_SEAMLESS_RENT_PAYMENTS =
  'To facilitate seamless rent payments, we need to connect to your bank securely through Plaid.';
export const PLAID_LETS_YOU_SECURELY_CONNECT_YOUR_FINANCIAL_ACCOUNTS_IN_SECONDS =
  'Plaid lets you securely connect your financial accounts in seconds';
export const STRIPE_LETS_YOU_SECURELY_CONNECT_YOUR_FINANCIAL_ACCOUNTS_IN_SECONDS =
  'Stripe lets you securely connect your financial accounts in seconds';
export const PLAID_DOESNT_SELL_PERSONAL_INFO = 'Plaid doesn’t sell personal info, and will only use it with your permission';
export const STRIPE_DOESNT_SELL_PERSONAL_INFO = 'Stripe doesn’t sell personal info, and will only use it with your permission';
export const BY_SELECTING_NEXT_YOU_AGREE_TO_THE = 'By selecting ‘’Next’’ you agree to the ';
export const PLAID_END_USER_PRIVACY_POLICY = 'Plaid End User Privacy Policy';
export const INVOICE_DETAILS = 'Invoice details';
export const PAYMENT_PLATFORM_FEES = 'Payment Platform Fees';
export const TOTAL_AMOUNT = 'TOTAL AMOUNT:';
export const HAVE_PAID = 'Have Paid';
export const PAYMENT_SUCCESS = 'Payment success!';
export const RENT_PAYMENT_SUCCESSFULLY_TRANSFERRED = 'Congratulations! Your rent payment has been successfully transferred using Plaid.';
export const OTHER_AMOUNT = 'Other Amount';
export const SELECT_THE_PAYMENT_METHOD_YOU_WANT_TO_USE = 'Select the payment method you want to use';
export const ADD_NEW_ACCOUNT = 'Add New Account';
export const ADD_NEW_CARD = 'Add New Card';
export const CURRENT_SELECTED = 'Currently selected:';
export const UPDATE_PAYMENT_METHODS = 'Update Payment Methods';
export const SET_UP = 'Set Up';
export const YOU_HAVE_NO_AUTO_PAYMENTS = 'You have no auto payments set up for this lease';
export const START_AND_EXPIRE_DATE = 'Start and expire date';
export const EXPIRE_DATE = 'Expire Date';
export const DELETE = 'Delete';
export const RUNNING_BALANCE = 'Running Balance';
export const BALANCE = 'Balance';
export const RENT_DETAILS = 'Rent Details';

export const TRANSACTION_DETAILS = 'Transaction Details';
export const MEMO = 'Memo';
export const TRANSACTION_DATE = 'Transaction Date';

export const REQUIRED_MESSAGE = '{name} is required';
export const OVERFLOW_MESSAGE = '{name} must be {length} characters or less';
export const RANGE_MESSAGE = '{name} must be between {min} and {max} characters';
export const MIN_LENGTH_MESSAGE = '{name} must be at least {minLength} characters';

export const VISA = 'Visa';
export const MASTERCARD = 'MasterCard';
export const AMERICAN_EXPRESS = 'American Express';
export const JCB = 'JCB';
export const UNION_PAY = 'Union Pay';
export const DINERS_CLUB = 'Diners Club';
export const PLAID = 'Plaid';

export const YOU_DO_NOT_PROVIDE_A_TRANSACTION_ID = 'You do not provide a transaction id';
export const CAN_NOT_FIND_THE_TRANSACTION_RECORD = 'Can not find the transaction record';
export const THERE_WERE_PROBLEMS_WITH_YOUR_RESULT = 'There were problems with your result';
export const NO_LEASE_SELECTED = 'No lease selected!';
export const COULD_NOT_FIND_PAYMENT_OPTIONS_FOR_THIS_LEASE = 'Could not find payment options for this lease!';
export const ELECTRONIC_BANK_TRANSFER = 'Electronic Bank Transfer';
export const ELECTRONIC_BANK_TRANSFER_FEE = '{number} processing fee';
export const CREDIT_DEBIT_CARD = 'Credit/Debit Card';
export const CREDIT_DEBIT_CARD_FEE = '{number} transaction fee';
export const NO_PLAID_ACCOUNTS_YET = 'No plaid accounts yet';
export const NO_CREDIT_AND_DEBIT_CARD_YET = 'No credit/debit cards yet';
export const REQUESTING_RESULTS_FROM_STRIPE = 'Requesting results from Stripe';
export const NO_CURRENT_BALANCE_DUE = 'No current balance due';
export const PAYMENT_FEATURE_IS_CURRENTLY_UNAVAILABLE =
  'Payment feature is currently unavailable, please contact support for more information';
export const THIS_LEASE_CURRENTLY_CAN_NOT_ACCEPTS_PAYMENTS =
  'This lease currently can not accepts payments, please contact support for more information';
export const THIS_LEASE_CURRENTLY_CAN_NOT_PAY_WITH_CARDS =
  'This lease currently can not pay with credit card or debit card, please contact support for more information';
export const THIS_LEASE_CURRENTLY_CAN_NOT_PAY_WITH_ELECTRONIC_BANK_TRANSFER =
  'This lease currently can not pay with electronic bank transfer, please contact support for more information';
export const CHECKING = 'Checking';
export const SAVINGS = 'Savings';

export const COMPANY_NAME = 'Company name';
